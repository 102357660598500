import BasicTable from "components/BasicTable";
import Button from "components/form/Button";
import Modal from "components/Modal";
import { SMIPermissionsCode } from "domains/Permissions/SMIPermissionsCode";
import useUserPermission from "domains/Permissions/useUserPermission";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { saveWebSubscription } from "services/webSubscriptionService";
import { fetchSelectedWebSubscriptionDetails } from "store/modules/administration/webSubscription/selectedWebSubscriptionDetailSlice";
import { UseWebSubscription } from "./webSubscriptionSearch";
import style from '../../../../styles/UserTabs.module.css'


export default function Details({ item }) {
    const verifyWebSubscriptionManage = useUserPermission(SMIPermissionsCode.WEB_SUBSCRIPTION_MANAGE);

    const webSubscriptionsHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value', selectable: true },
    ]

    const firstWebSubscriptionData = UseWebSubscription(item);
    const [webSubscriptionData, setWebSubscriptionData] = useState(firstWebSubscriptionData)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [handleValueChange, setHandleValueChange] = useState(true)

    const [originalValues, setOriginalValues] = useState({})
    const [changedValues, setChangedValues] = useState({})

    useEffect(() => {
        let firstWebSubscriptionDataCopy = { ...firstWebSubscriptionData }
        setWebSubscriptionData(firstWebSubscriptionDataCopy)

        setOriginalValues({
            discord: firstWebSubscriptionData.discord,
            userId: firstWebSubscriptionData.userId,
            smallUserId: firstWebSubscriptionData.userId,
            userFirstName: firstWebSubscriptionData.userFirstName,
            userLastName: firstWebSubscriptionData.userLastName,
            lastUpdate: firstWebSubscriptionData.lastUpdate,
            status: firstWebSubscriptionData.status,
        })

        setChangedValues({
            discord: firstWebSubscriptionData.discord,
            userId: firstWebSubscriptionData.userId,
            smallUserId: firstWebSubscriptionData.userId,
            userFirstName: firstWebSubscriptionData.userFirstName,
            userLastName: firstWebSubscriptionData.userLastName,
            lastUpdate: firstWebSubscriptionData.lastUpdate,
            status: firstWebSubscriptionData.status,
        })

    }, [firstWebSubscriptionData])

    useEffect(() => {
        let originalValuesProps = Object.getOwnPropertyNames(originalValues);
        let changedValuesProps = Object.getOwnPropertyNames(changedValues);

        if (originalValuesProps.length != changedValuesProps.length) {
            setHandleValueChange(false)
            return;
        }

        for (let i = 0; i < originalValuesProps.length; i++) {
            let propName = originalValuesProps[i];

            if (originalValues[propName] !== changedValues[propName]) {
                setHandleValueChange(false)
                return;
            }
        }
        setHandleValueChange(true)

    }, [item, changedValues])

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setCurrentItem({});
        setInputValue("");
        setIsModalOpen(false)
    }

    const handleSelectRow = (item) => {
        if (item.name == "Status") {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(item.value)
            handleOpenModal()
        }
    }

    const handleOnChangeInput = (event) => {
        setInputValue(event.target.value)
    }

    const handleSubmit = () => {
        let webSubscriptionDataCopy = { ...webSubscriptionData, tableProperties: [...webSubscriptionData.tableProperties] };
        let changedValuesCopy = { ...changedValues }

        if (inputValue != '') {
            for (let i = 0; i < webSubscriptionData.tableProperties.length; i++) {
                if (webSubscriptionData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                    webSubscriptionDataCopy.tableProperties[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                    webSubscriptionDataCopy[currentItem.key] = inputValue;
                    changedValuesCopy[currentItem.key] = inputValue;
                }
            }
            setChangedValues(changedValuesCopy)
            setWebSubscriptionData(webSubscriptionDataCopy);
        }
        setCurrentItem({});
        setInputValue("");
        handleCloseModal();

    }

    const handleDiscardChanges = () => {
        setChangedValues({ ...originalValues })
        setWebSubscriptionData(firstWebSubscriptionData)
    }

    const dispatch = useDispatch()

    const handleSaveChanges = async () => {
        try {
            let originalValuesCopy = { ...originalValues }
            let changedValuesCopy = { ...changedValues }
            let originalValuesProps = Object.getOwnPropertyNames(originalValues);

            const propertyStatus = {
                'Inscrição realizada': 'pending',
                'Em análise': 'review',
                'Aceito': 'approved',
                'Negado': 'denied'
            }

            const propertyApi = {
                status: 'status',
            }

            let changes = {}

            for (let i = 0; i < originalValuesProps.length; i++) {
                let propName = originalValuesProps[i];
                if (originalValues[propName] !== changedValues[propName]) {
                    changes[propertyApi[propName]] = changedValues[propName]
                }
            }

            if (changes['status']) {
                changes['status'] = propertyStatus[changes['status']]
            }

            await saveWebSubscription(item.userId, changes)

            setChangedValues(changedValuesCopy)
            setOriginalValues(changedValuesCopy)

            fetchSelectedWebSubscriptionDetails(dispatch, { userId: item.userId });
            toast.success('Dados salvos com Sucesso!')
        }
        catch {
            toast.error('Erro ao salvar!')
        }

    }

    return (
        <>
            <div className='ring-2 ring-black ring-opacity-10 rounded-lg p-2'>
                <BasicTable
                    onSelectRow={handleSelectRow}
                    headers={webSubscriptionsHeaders}
                    items={webSubscriptionData.tableProperties}
                    slotHeader={(handleValueChange) ? "" :
                        <div className={style.header}>
                            <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                            <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                        </div>
                    }
                />
            </div>
            {verifyWebSubscriptionManage && <Modal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
            >
                <div className={style.modalContent}>
                    <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>

                    
                        <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                            <option key={'pending'} value={'Inscrição realizada'}>Inscrição realizada</option>
                            <option key={'review'} value={'Em análise'}>Em análise</option>
                            <option key={'approved'} value={'Aceito'}>Aceito</option>
                            <option key={'denied'} value={'Negado'}>Negado</option>
                        </select>
                    
                    <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                </div>
            </Modal>}
        </>
    )
}
