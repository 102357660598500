class SMIPermissionsCode {
    static MOD_LOGIN = 23;
    static MOD_DASHBOARD_SHIFTLEAD = 182; //
    static MOD_SHIFTLEAD_ONLINE_STATUS = 185; //
    static MOD_DASHBOARD_ESCALATION = 187; //
    static MOD_DASHBOARD_CONTENT = 183;//
    static MOD_USER_SEARCH = 75;// aba (como?)
    static MOD_USER_CURRENCY_VIEW = 214;// ?
    static MOD_USER_BASIC_VIEW = 186;// 
    static MOD_USER_BASIC_EDIT = 98;// 
    static MOD_USER_EMAIL_VIEW = 217;// não é o mesmo que mail?
    static MOD_USER_EMAIL_EDIT = 218;// não tem 
    static MOD_USER_PASSWORD_EDIT = 219;// não tem 
    static MOD_USER_SECRET_VIEW = 220;// não tem 
    static MOD_USER_SECRET_EDIT = 199;// não tem 
    static MOD_USER_GROUP_VIEW = 221;// 
    static MOD_USER_GROUP_EDIT = 51;//  
    static MOD_USER_TOKENS_ADD = 52;//
    static MOD_USER_TOKENS_REMOVE = 222;//
    static MOD_USER_GOLD_ADD = 53;//
    static MOD_USER_GOLD_REMOVE = 173;//
    static MOD_USER_CP_ADD = 241;//
    static MOD_USER_CP_REMOVE = 242;//
    static MOD_CAN_ADD_AUTHENTICATOR = 213;// não tem
    static MOD_CAN_REMOVE_AUTHENTICATOR = 212;// 
    static MOD_USER_INFO_VIEW = 223;// ?
    static MOD_USER_IP_VIEW = 224;//
    static MOD_USER_NOTES_VIEW = 225;//
    static MOD_USER_NOTES_EDIT = 198;//
    static MOD_USER_FLAGS_VIEW = 226;// não tem
    static MOD_USER_FLAGS_EDIT = 118;// não tem
    static MOD_USER_CHANGES_VIEW = 227;//
    static MOD_USER_CONNECTION_VIEW = 228;// não tem
    static MOD_USER_CONNECTION_REMOVE = 174;// não tem
    static MOD_USER_VIEW_SPACE_ACTIVITY = 179;// não tem
    static MOD_USER_VIEW_CHANNEL_ACTIVITY = 180;// não tem
    static MOD_USER_VIEW_CONVERSATION_ACTIVITY = 181;// não tem
    static MOD_USER_CP = 193;// não tem
    static MOD_USER_MAIL = 126;// não é o mesmo que email?
    static MOD_USER_MAIL_COMPOSE = 194;// ? (fazer)
    static MOD_USER_LOYALTY_CHALLENGE = 195;// não tem
    static MOD_USER_INVITES = 196;//não tem
    static MOD_USER_INVITES_REVERSE = 123;//não tem
    static MOD_USER_CREATE = 208;//
    static MOD_USER_BOOT = 215;// não tem
    static MOD_USER_LOGOUT = 216;//
    static MOD_AVATAR_SEARCH = 188;//
    static MOD_AVATAR_VIEW = 189;//
    static MOD_AVATAR_CHANGE = 190;//
    static MOD_AVATAR_EXP_CHANGE = 54;//
    static MOD_AVATAR_XP = 197;//
    static MOD_AVATAR_CONFIG_VIEW = 230;//
    static MOD_AVATAR_CONFIG_EDIT = 231;// não tem
    static MOD_AVATAR_PETS = 191;//
    static MOD_ABUSE_MANAGE = 76;//
    static MOD_ABUSE_REPORT_HIGHLIGHT = 113;// não tem
    static MOD_ABUSE_REPORT_GOTO_SPACE = 233;// 
    static MOD_UNREAD_REPORT_SUMMARY = 94;// não tem
    static MOD_VIEW_INFRACTIONS = 158;//
    static MOD_USE_INFRACTIONS_TIER1 = 203;// não tem
    static MOD_USE_INFRACTIONS_TIER2 = 204;// não tem
    static MOD_USE_INFRACTIONS_ESCALATION = 205;// não tem
    static MOD_USE_INFRACTIONS_ANTIFRAUD = 206;// não tem
    static MOD_USE_INFRACTIONS_IPBLACKLIST = 207;// não tem
    static MOD_GLOBALLY_INFRACT_USERS = 159;// 
    static MOD_SEE_INFRACTION_PUNISHMENTS = 162;// 
    static MOD_REVERSE_INFRACTION_PUNISHMENTS = 184;// não tem
    static MOD_REVERSE_INFRACTIONS = 178;//
    static MOD_EVENT_MANAGE = 117;// não tem
    static MOD_VIEW_IP_BLACKLIST = 168;// 
    static MOD_ADD_IP_BLACKLIST_ENTRIES = 169;//
    static MOD_ADD_PARTIAL_IP_BLACKLIST_ENTRIES = 170;// não tem
    static MOD_REMOVE_ALL_IP_BLACKLIST_ENTRIES = 171;// 
    static MOD_SWEAR_WORDS_MANAGE = 255;// 
    static MOD_VIEW_AVATAR_NO_NAME = 259;//
    static MOD_ADD_AVATAR_NO_NAME_ENTRIES = 260;//
    static MOD_REMOVE_AVATAR_NO_NAME_ENTRIES = 261;//
    static MOD_MANAGE = 160;// não tem
    static MOD_CONTENT_MANAGE = 133;// não tem
    static REVENUE_SUBSCRIPTION_LOGS = 91;// não tem
    static REVENUE_OFFER_LOGS = 124;// não tem
    static REVENUE_BUNDLES = 142;// não tem
    static REVENUE_DISCOUNTS = 244;// não tem
    static REVENUE_PAYMENT_METHODS = 165;// não tem
    static REVENUE_OFFER_NETWORKS = 177;// não tem
    static REVENUE_TRANSACTION_LOGS = 192;//
    static REVENUE_ANALYSE_TRANSACTIONS = 210;//
    static REVENUE_USER_SPEND_LEVEL_INFO = 163;// não tem
    static REVENUE_SPEND_LEVELS = 164;// não tem
    static ITEMS_SEARCH = 103;//
    static ITEMS_MULTI_SELECT = 109;// não tem
    static ITEMS_STOP_SALES = 235;// não tem
    static ITEMS_SPACE_REMOVE = 125;//
    static ITEMS_REMOVE = 108;// não tem
    static ITEMS_EDIT_CONFIG = 236;//não tem
    static ITEMS_ADD = 107;// não tem
    static ITEMS_MANAGE_CATALOG = 146;//
    static ITEMS_MANAGE_SPINTOWIN = 143;//
    static ITEMS_EDIT_KUDOS_REWARDS = 155;// não tem
    static SPACE_SEARCH = 78;// 
    static SPACE_DETAILS_VIEW = 237;//
    static SPACE_DETAILS_EDIT = 99;//
    static SPACE_MEMBERS_VIEW = 238;//
    static SPACE_MEMBERS_EDIT = 239;//
    static SPACE_INSTANCES_VIEW = 234;// não tem
    static SPACE_INSTANCES_CLOSE = 240;// não tem
    static MOD_SPACE_CREATE = 121;// não tem
    static MOD_SPACE_CLONE = 120;// não tem
    static MOD_SPACE_CHATLOGS = 111;//
    static MOD_SPACE_CHATLOGS_LONG = 112;// ?
    static MOD_SPACE_CATALOG = 229;//
    static MOD_MISSION_MANAGE = 92;//
    static MOD_MISSION_CHANGE = 102;//
    static MOD_MISSION_REWARD_REVERSE = 140;// não tem
    static MOD_MISSION_IMPORT_EXPORT = 141;// não tem
    static MOD_MISSION_TASKS_SEARCH = 200;// não tem
    static MOD_MISSION_TASKS_VIEW = 201;// não tem
    static MOD_MISSION_TASKS_CHANGE = 202;// não tem
    static MOD_GROUP_MANAGE = 136;//
    static MOD_TOURNAMENT_MANAGE = 81;
    static MOD_TOURNAMENT_CHANGE = 100;
    static MOD_TOURNAMENT_CURRENCY = 110;// não tem
    static MOD_GAME_MANAGE = 82;// não tem
    static MOD_GAME_CHANGE = 101;// não tem
    static MOD_ADMIN = 49;// 
    static MOD_COMMUNICATION_FILTERS = 132;// não tem
    static NEWS_MANAGE = 250;//
    static BROADCAST_NOTIFICATION_MANAGE = 251;//
    static PACKAGES_MANAGE = 270;//
    static PACKAGES_VIEW = 271;//
    static WEB_SUBSCRIPTION_VIEW = 272;//
    static WEB_SUBSCRIPTION_MANAGE = 273;//
}

export {SMIPermissionsCode}