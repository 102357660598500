class Package {
    constructor({ packageId, packageName, active }) {
        this.packageId = packageId
        this.packageName = packageName
        this.active = active
    }

    get() {
        return {
            packageId: this.packageId,
            packageName: this.packageName,
            active: this.active,
        }
    }
}

export default Package