import DefaultForm from "components/form/DefaultForm"
import { uuidMask } from "helpers/masks"
import { useDispatch } from "react-redux";
import { fetchPackagesFilters } from "store/modules/packages/packagesSlices";

export default function PackagesFilter({onFilterChange, defaultValues}){
    const dispatch = useDispatch();

    const handleSubmit = async (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
        newValue[name] = value;

        await fetchPackagesFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'packageId',
            label: 'ID do Pacote',
            value: defaultValues.packageId,
            placeholder: 'ID do Pacote',
            type: 'text',
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'packageName',
            label: 'Nome do Pacote',
            value: defaultValues.packageName,
            placeholder: 'Nome do Pacote',
            type: 'text',
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'active',
            value: defaultValues.active.value ?? 'all',
            label: 'Ativo',
            type: 'select',
            items: [
                {
                    value: 'all',
                    name: 'Todos'
                },
                {
                    value: 'true',
                    name: 'Sim'
                },
                {
                    value: 'false',
                    name: 'Não'
                }
            ],
            order: 'A-Z',
            gridClass: 'col-span-4',
        }
    ]

    return <DefaultForm fields={fields} onChange={handleSubmit}/>
}