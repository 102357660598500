import { createSlice } from '@reduxjs/toolkit'
import { getWebSubscription } from 'services/webSubscriptionService';

const selectedWebSubscriptionSlice = createSlice({
    name: 'selectedWebSubscription',
    initialState: {},
    reducers: {
        setSelectedWebSubscription: (state, action) => {
            let webSubscription;

            if (Array.isArray(action.payload) && action.payload.length > 0) {
                webSubscription = action.payload[0]
            } else {
                webSubscription = {}
            }            

            return webSubscription
        },
    },
})

export const selectWebSubscriptionForDetails = (params) => async (dispatch) => {
    try {
        const selectedWebSubscription = await getWebSubscription(params, 0)
        dispatch(setSelectedWebSubscription(selectedWebSubscription))
    } catch (error) {
        console.error('Erro ao carregar Inscrição:', error)
    }
}

export const { setSelectedWebSubscription } = selectedWebSubscriptionSlice.actions
export default selectedWebSubscriptionSlice.reducer
