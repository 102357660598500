import { uuidMask } from 'helpers/masks'
import DefaultForm from 'components/form/DefaultForm'
import { useDispatch } from 'react-redux';
import { getInfractionOptions, getInfractionSteps } from 'services/infractionService';
import { useEffect, useState } from 'react';
import { fetchAllInfractionsFilters } from 'store/modules/moderation/infractions/allInfractionsSlice';

export default function FormSearch({ onFilterChange, defaultValues }) {

    const [infractionOptions, setInfractionOptions] = useState([]);
    const [propName, setPropName] = useState({});
    const [infractionSteps, setInfractionSteps] = useState([]);
    const [propNameStep, setPropNameStep] = useState({});

    useEffect(() => {
        handleInfractionOptions()
        handleInfractionSteps()
    }, [])

    const handleInfractionOptions = async () => {
        let tempInfractionOptions = []
        let tempPropName = {}
        const apiInfractionOptions = await getInfractionOptions()

        tempInfractionOptions.push({
            value: '',
            name: 'Todas'
        })

        apiInfractionOptions.map((item) => {
            tempInfractionOptions.push({
                value: item.typeId,
                name: `${item.desc}`
            })

            tempPropName[`${item.typeId}`] = item.desc
        })

        setInfractionOptions(tempInfractionOptions)
        setPropName(tempPropName)
    }

    const handleInfractionSteps = async () => {
        let tempInfractionSteps = []
        let tempPropName = {}
        const apiInfractionOptions = await getInfractionSteps()

        tempInfractionSteps.push({
            value: '',
            name: 'Todas'
        })

        apiInfractionOptions.map((item) => {
            tempInfractionSteps.push({
                value: item.stepId,
                name: `${item.desc}`
            })

            tempPropName[`${item.stepId}`] = item.desc
        })

        setInfractionSteps(tempInfractionSteps)
        setPropNameStep(tempPropName)
    }


    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({ form: formData, isValid: isValid })

        let newValue = {};
        if (name == 'infractionId') {
            newValue[name] = { name: propName[value], value };
        }
        else if (name == 'punishmentId'){
            newValue[name] = { name: propNameStep[value], value };
        }
        else {
            newValue[name] = value;
        }

        fetchAllInfractionsFilters(dispatch, { ...defaultValues, ...newValue })
    }

    const fields = [
        {
            name: 'userId',
            label: 'ID Usuário',
            value: defaultValues.userId,
            placeholder: 'ID Usuário',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'dateFrom',
            label: 'De',
            value: defaultValues.dateFrom,
            placeholder: '',
            type: 'date',
            gridClass: 'col-span-4'
        },
        {
            name: 'dateTo',
            label: 'Até',
            value: defaultValues.dateTo,
            placeholder: '',
            type: 'date',
            gridClass: 'col-span-4'
        },
        {
            name: 'infractionId',
            label: 'Infração',
            value: defaultValues.infractionId.value ?? '',
            placeholder: 'Todas',
            type: 'select',
            items: infractionOptions,
            order: 'A-Z',
            //defaultItem: (defaultValues.infractionId != '') ? defaultValues.infractionId.name : 'Todas',
            gridClass: 'col-span-6',
        },
        {
            name: 'punishmentId',
            label: 'Punição',
            value: defaultValues.punishmentId.value ?? '',
            placeholder: 'Todas',
            type: 'select',
            items: infractionSteps,
            order: 'A-Z',
            //defaultItem: (defaultValues.punishmentId != '') ? defaultValues.punishmentId.name : 'Todas',
            gridClass: 'col-span-6',
        },
    ]


    return (
        <>
            <DefaultForm fields={fields} onChange={handleSubmit} />
        </>
    )
}
