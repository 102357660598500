import moment from "moment"

class WebSubscriptionDetail {
    constructor({ discord, userId, status, lastUpdate, userFirstName, userLastName }) {
        this.discord = discord
        this.userId = userId
        this.status = status
        this.lastUpdate = lastUpdate

        this.userFirstName = userFirstName
        this.userLastName = userLastName
    }

    get() {
        return {
            tableProperties: [
                {
                    key: 'userId',
                    name: "ID do Usuário",
                    value: this.userId,
                },
                {
                    key: 'userName',
                    name: "Nome de Usuário",
                    value: this.fullName(this.userFirstName, this.userLastName),
                },
                {
                    key: 'discord',
                    name: "Discord",
                    value: this.discord,
                },
                {
                    key: 'lastUpdate',
                    name: "Última Atualização",
                    value: moment(this.date).format('DD/MM/YYYY') + " " + moment(this.date).format('HH:mm'),
                },
                {
                    key: 'status',
                    name: "Status",
                    value: this.statusProp(this.status) ?? 'Status Desconhecido',
                },
            ],
            discord: this.discord,
            userId: this.userId,
            userFirstName: this.userFirstName,
            userLastName: this.userLastName,
            userName: this.fullName(this.userFirstName, this.userLastName),
            lastUpdate: moment(this.lastUpdate).format('DD/MM/YYYY') + " " + moment(this.lastUpdate).format('HH:mm'),
            status: this.statusProp(this.status) ?? 'Status Desconhecido',

        }

    }

    statusProp(status) {
        const statusName = {
            pending: 'Inscrição realizada',
            review: 'Em análise',
            approved: 'Aceito',
            denied: 'Negado',
        }
        return statusName[status]
    }

    fullName(userFirstName, userLastName) {
        if (userFirstName === null || userLastName === null) return "Usuário Apagado"
        else return `${userFirstName} ${userLastName}`
    }

}

export default WebSubscriptionDetail