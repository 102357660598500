import { createSlice } from '@reduxjs/toolkit'
import { getSelectedPackageDetails } from 'services/packagesService';

const selectedPackageDetailsSlice = createSlice({
    name: 'selectedPackageDetails',
    initialState: {
        details: {
            tableProperties: []
        }
    },
    reducers: {
        setSelectedPackageDetailsDetails: (state, action) => {
            return {...state, details: action.payload}
        },
    },
})

export async function fetchSelectedPackageDetails(dispatch, params){
    try{
        const details = await getSelectedPackageDetails(params);
        console.log(details)
        dispatch(setSelectedPackageDetailsDetails(details));
    }
    catch(error){
        console.error('Erro ao obter Detalhes', error)
    }
}

export const { setSelectedPackageDetailsDetails } = selectedPackageDetailsSlice.actions
export default selectedPackageDetailsSlice.reducer