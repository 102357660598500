import DefaultForm from "components/form/DefaultForm";
import { useDispatch } from "react-redux";
import { fetchXpLogsFilters } from "store/modules/users/avatars/xpLogsSlice";

export default function XpFilter ({onFilterChange, defaultValues}) {

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
        

        if(name == 'xpType'){
            let propName = {
                1: 'Artist',
                2: 'Explorer',
                3: 'Gamer',
                4: 'Social',
                5: 'Arena',
                6: 'Farmer',
                7: 'Crafter',
                8: 'Primary',
                9: 'CP',
            }
            newValue[name] = {name: propName[value], value};
        }
        else{
            newValue[name] = value;
        }

        fetchXpLogsFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'xpType',
            label: 'Tipo de XP',
            value: defaultValues.xpType.value ?? '',
            placeholder: 'Tipo de XP',
            type: 'select',
            items: [
                {
                    value: '',
                    name: '-- Tipo de XP --',

                },
                {
                    value: 1,
                    name: 'Artist'
                },
                {
                    value: 2,
                    name: 'Explorer'
                },
                {
                    value: 3,
                    name: 'Gamer'
                },
                {
                    value: 4,
                    name: 'Social'
                },
                {
                    value: 5,
                    name: 'Arena'
                },
                {
                    value: 6,
                    name: 'Farmer'
                },
                {
                    value: 7,
                    name: 'Crafter'
                },
                {
                    value: 8,
                    name: 'Primary'
                },
                {
                    value: 9,
                    name: 'CP'
                },
            ],
            order: 'A-Z',
            //defaultItem: (defaultValues.xpType !=  '') ? defaultValues.xpType.name : '-- Tipo de XP --',
            gridClass: 'col-span-4',
        },
        {
            name: 'dateFrom',
            label: 'De:',
            value: defaultValues.dateFrom,
            placeholder: '',
            type: 'date',
            gridClass: 'col-span-4'
        },
        {
            name: 'dateTo',
            label: 'Até:',
            value: defaultValues.dateTo,
            placeholder: '',
            type: 'date',
            gridClass: 'col-span-4'
        },
    ]
    

    return (
        <>
            <DefaultForm fields={ fields } onChange={handleSubmit} />
        </>
    )
}
