import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectedWebSubscriptionDetails } from "store/modules/administration/webSubscription/selectedWebSubscriptionDetailSlice";

export function UseWebSubscription(item){
    const webSubscription = useSelector((state) => {
        return (state.selectedWebSubscriptionDetails.details) ? state.selectedWebSubscriptionDetails.details : {tableProperties: []}
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        fetchSelectedWebSubscriptionDetails(dispatch, { userId: item.userId });
    }, [item])

    return webSubscription
}