import WebSubscriptions from "Pages/Administration/WebSubscription";
import Missions from "Pages/Missions";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchWebSubscriptionFilters } from "store/modules/administration/webSubscription/webSubscriptionSlice";

export function WebSubscriptionUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get("userId");
    const discord = searchParams.get("discord");
    const status = searchParams.get("status");

    let propName = {
        pending: 'Inscrição realizada',
        review: 'Em análise',
        approved: 'Aceito',
        denied: 'Negado',
    }
    
    const dispatch = useDispatch()

    if (userId || discord || status) {
        fetchWebSubscriptionFilters(dispatch, {userId, discord, status: status ? {name: propName[status], value: status} : {name: 'Status Desconhecido', value: ''}})
        return <WebSubscriptions userId={userId ?? null} discord={discord ?? null} status={status ?? ''}/>;
    }
    else{
        return <WebSubscriptions/>;
    }

}