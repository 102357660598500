import handleRequestError from 'helpers/apiErrorHandler';
import api, { apiNormalize } from './api'
import { packageDetailsMapping, packagesMapping } from 'domains/Packages/mapping';
import Package from 'domains/Packages/Package';
import PackageDetail from 'domains/Packages/PackageDetail';

export async function getPackages(params, skip) {
    try {
        const response = await api.get("/packages", { params: { ...params, skip } });
        const packages = apiNormalize.responseMapping(response.data, packagesMapping)
        const normalizedPackages = packages.map(pack => new Package(pack).get());
        return normalizedPackages
    } catch (error) {
        handleRequestError(error)
    }
}

export const getPackage = async (params) => {
    try {
        const response = await api.get('/packages/byId', { params })
        const pack = apiNormalize.responseMapping(response.data, packagesMapping)
        const normalizedPackages = pack.map(pack => new Package(pack).get());
        return normalizedPackages
    } catch (error) {
        handleRequestError(error)
    }
}

export const getSelectedPackageDetails = async (params) => {
    try {
        const response = await api.get('/packages/details', { params })
        const detail = packageDetailsMapping(response.data[0])
        console.log(detail)
        const normalizedDetail = new PackageDetail(detail).get()
        return normalizedDetail
    } catch (error) {
        handleRequestError(error)
    }
}

export const savePackageItems = async (packageId, items) => {
    try{
        const response = await api.post(`/packages/${packageId}/savePackageItems`, {items});
        return response.data;
    } catch (error) {
        handleRequestError(error)
    }
}

export const savePackageImg = async (packageId, img, name, language) => {
    try {
        const formData = new FormData();
        formData.append('img', img);
        formData.append('name', name);
        const response = await api.post(`/packages/${packageId}/savePackageImg/${language}`, formData);
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const savePackage = async (packageId, changes) => {
    try{
        const response = await api.post(`/packages/${packageId}/savePackage`, {changes})
        return response.data
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}

export const createPackage = async (changes, imgs) => {
    try {
        const formData = new FormData();

        Object.keys(imgs).forEach((key, index) => {
            const img = imgs[key];
            formData.append(`img[]`, img);
        });
        
        formData.append('changes', JSON.stringify(changes));

        const response = await api.post(`/packages/createPackage`, formData);
        return response.data;
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const deletePackage = async (params) => {
    try {
        const response = await api.post(`/packages/deletePackage`, { params })
        return response.data
    }
    catch (error) {
        console.log(error)
        handleRequestError(error)
    }
}