import DefaultForm from "components/form/DefaultForm"
import { uuidMask } from "helpers/masks"
import { useDispatch } from "react-redux";
import { fetchWebSubscriptionFilters } from "store/modules/administration/webSubscription/webSubscriptionSlice";

export default function WebSubscriptionsFilter({ onFilterChange, defaultValues }) {
    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({ form: formData, isValid: isValid })

        let newValue = {};

        if (name == 'status') {
            let propName = {
                pending: 'Inscrição realizada',
                review: 'Em análise',
                approved: 'Aceito',
                denied: 'Negado',
            }
            newValue[name] = { name: propName[value], value };
        }
        else {
            newValue[name] = value;
        }

        fetchWebSubscriptionFilters(dispatch, { ...defaultValues, ...newValue })
    }

    const fields = [
        {
            name: 'userId',
            label: 'ID do Usuário',
            value: defaultValues.userId,
            placeholder: 'ID do Usuário',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'discord',
            label: 'Discord',
            value: defaultValues.discord,
            placeholder: 'Discord',
            type: 'text',
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'status',
            label: 'Status',
            value: defaultValues.status.value ?? '',
            placeholder: 'Status',
            type: 'select',
            items: [
                {
                    value: '',
                    name: 'Todos'
                },
                {
                    value: 'pending',
                    name: 'Inscrição realizada'
                },
                {
                    value: 'review',
                    name: 'Em análise'
                },
                {
                    value: 'approved',
                    name: 'Aceito'
                },
                {
                    value: 'denied',
                    name: 'Negado'
                }
            ],
            order: 'A-Z',
            gridClass: 'col-span-4',
        },
    ]

    return <DefaultForm fields={fields} onChange={handleSubmit} />
}