import Packages from "Pages/Packages";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchPackagesFilters } from "store/modules/packages/packagesSlices";

export function PackagesUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const packageId = searchParams.get("packageId");
    const packageName = searchParams.get("packageName");
    const active = searchParams.get("active");
    

    const dispatch = useDispatch()

    if (packageId || packageName || active) {
        fetchPackagesFilters(dispatch, {packageId, packageName, active})
        return <Packages packageId={packageId ?? null} packageName={packageName ?? null} active={active ?? null}/>;
    }
    else{
        return <Packages/>;
    }

}