import React, { useState, useEffect } from 'react'
import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../../../styles/Search.module.css'
import Modal from 'components/Modal'
import Details from './Partials/Details'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'
import { fetchWebSubscription } from 'store/modules/administration/webSubscription/webSubscriptionSlice'
import WebSubscriptionsFilter from './webSubscriptionsFilter'
import { selectWebSubscriptionForDetails } from 'store/modules/administration/webSubscription/selectedWebSubscriptionSlice'

export default function WebSubscriptions({ userId, discord, status}) {
    const verifyWebSubscription = useUserPermission(SMIPermissionsCode.WEB_SUBSCRIPTION_VIEW);

    const [webSubscriptionsHeaders, setWebSubscriptionsHeaders] = useState([
        { title: 'Nome do Usuário', key: 'userName', copy: 'userId', detailable: 'true', appear: true },
        { title: 'Discord', key: 'discord', appear: true },
        { title: 'Última Atualização', key: 'lastUpdate', appear: true },
        { title: 'Status', key: 'status', appear: true },
       

    ]);

    const [webSubscriptionsMomentHeaders, setWebSubscriptionsMomentHeaders] = useState([...webSubscriptionsHeaders]);

    const handleAppearHeader = (item) => {
        setWebSubscriptionsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setWebSubscriptionsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }


    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const webSubscriptions = useSelector((state) => {
        return Array.isArray(state.webSubscriptions.data) ? state.webSubscriptions.data : []
    })

    const selectedWebSubscription = useSelector((state) => {
        return state.selectedWebSubscription
    })

    const skip = useSelector((state) => {
        return (state.webSubscriptions.currentSkip) ? state.webSubscriptions.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.webSubscriptions.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.webSubscriptions.filters) ? state.webSubscriptions.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [webSubscriptions]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (userId || discord || status) {
            setLoading(true)
            fetchWebSubscription(dispatch, { userId, discord, status }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.userId) query.append('userId', filter.form.userId);
        if (filter.form.discord) query.append('discord', filter.form.discord);
        if (filter.form.status) query.append('status', filter.form.status);
        window.history.pushState({}, '', `/administration/webSubscriptions?${query.toString()}`);
        fetchWebSubscription(dispatch, filter.form, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchWebSubscription(dispatch, filter.form, skip + 200);
    })

    
    const handleWebSubscriptionDetails = (item) => {
        dispatch(selectWebSubscriptionForDetails({ userId: item.userId}))
    }

    return (
        <div className='grid grid-cols-12 gap-4'>

            {verifyWebSubscription && <div className='col-span-8'>
                <BasicTable
                    onAppearHeader={handleAppearHeader}
                    headerControl={true}
                    originalHeaders={webSubscriptionsHeaders}
                    headers={webSubscriptionsMomentHeaders}
                    slotHeader={<WebSubscriptionsFilter onFilterChange={handleSetFilter} defaultValues={filters} />}
                    title='Buscar Inscrições'
                    onDetailRow={handleWebSubscriptionDetails}
                    isLoading={isLoading && webSubscriptions.length === 0}
                    slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                    items={webSubscriptions}
                    slotFooter={
                        <div className={styles.slotFooter}>
                            <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                            {(webSubscriptions.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : webSubscriptions.length} resultados</span>}
                        </div>
                    }
                />
            </div>}
            <div className='col-span-4'>
                {selectedWebSubscription.userId && <Details item={selectedWebSubscription} />}
            </div>
        </div>
    )
}
