import { Paths } from "paths/Paths"

const modelItemsMapping = item => ({
    id: item._id ?? null,
    modelId: item.model_id ?? null,
    modelName: item.model_desc ?? '-',
    modelDetails: item.model_details ?? '-',
    modelTags: item.model_tags ?? '-',
    goldPrice: item.model_price_gold ?? null,
    tokenPrice: item.model_price_tokens ?? null,
    modelIcon: `${Paths.libraryPath}${item.model_icon}` ?? null,
    modelXml: `${Paths.libraryPath}${item.model_source}` ?? null,
    

})

export {modelItemsMapping}