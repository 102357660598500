const packagesMapping = (item) => ({
    packageId: item._id ?? null,
    packageName: item.name ?? null,
    active: item.disabled ? "Não" : "Sim",
})

const packageDetailsMapping = (item) => ({
    packageId: item._id ?? null,
    name: item.name ?? null,
    fullWidth: item.fullWidth ? "Sim" : "Não",
    sortIndex: item.sortIndex ?? null,
    metadata: item.metadata ?? null,
    disabled: item.disabled ? "Sim" : "Não",
    hasVipDiscount: item.hasVipDiscount ? "Sim" : "Não",
    currency: item.currency ?? null,
    images: item.images ?? null,
    prices: item.prices ?? null,
    discountEndDate: item.discountEndDate ?? '-',
    discountStartDate: item.discountStartDate ?? '-',
    lifetimeLimit: item.lifetimeLimit ?? '-',
    value: item.value ?? null,
    priceDiscount: item.priceDiscount ?? 0,
    startDate: item.startDate ?? '-',
    endDate: item.endDate ?? '-',
    description: item.description ?? '-',

})

export {packagesMapping, packageDetailsMapping}