import { getPackages } from "services/packagesService";

const { createSlice } = require("@reduxjs/toolkit");

const packagesSlice = createSlice({
    name:"packages",
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            packageId: '',
            packageName: '',
            active: ''
        }
    },
    reducers: {
        setPackagesData: (state, action) => {
            return {...state, data: action.payload}
        },
        addPackagesData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setPackagesFilter: (state, action) => {
            return {...state, filters: action.payload}
        },
    },
})

export async function fetchPackages(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const packages = await getPackages(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addPackagesData(packages))
        }
        else{
            dispatch(setPackagesData(packages));
        }
        
        if(packages.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter Packages', error);
        dispatch(setCurrentSkip(0));
        dispatch(setPackagesData([]));
        dispatch(setHasMore(false));
    }
}

export async function fetchPackagesFilters(dispatch, params){
    dispatch(setPackagesFilter(params));
}

export const { setPackagesData, setCurrentSkip, setHasMore, addPackagesData, setPackagesFilter } = packagesSlice.actions
export default packagesSlice.reducer