import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectedPackageDetails } from "store/modules/packages/selectedPackageDetailSlice";

export function UsePackage(item){
    const pack = useSelector((state) => {
        return (state.selectedPackageDetails.details) ? state.selectedPackageDetails.details : {tableProperties: []}
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        fetchSelectedPackageDetails(dispatch, { packageId: item.packageId });
    }, [item])

    return pack
}